import React from 'react'

const LyricsUI = ({ numberAndDelay, setNumberAndDelay, totalNumber, lrcText, trackid }) => {
  return (
    <div className="lyrics-ui">
      <div className="lyrics-title">
        <div>
          {'Delay ' + numberAndDelay[1] + 's'}
        </div>
        <div>
          {'Lyric ' + (numberAndDelay[0] + 1) + '/' + (totalNumber ? totalNumber : 'X')}
        </div>
      </div>
      <div className="lyrics-button enlarge-lyrics" onClick={(e) => {
        setNumberAndDelay((arr) => [arr[0], arr[1] + 1]);
      }}><i className="ph ph-clock-counter-clockwise"></i></div>
      <div className="lyrics-button enlarge-lyrics" onClick={(e) => {
        setNumberAndDelay((arr) => [arr[0], arr[1] - 1]);
      }}><i className="ph ph-clock-clockwise"></i></div>
      <div className="lyrics-button enlarge-lyrics" onClick={(e) => {
        const send_json = {
          'lyric': lrcText,
          'delay': numberAndDelay[1],
          'trackid': trackid
        };
        fetch('http://192.168.0.101:3500/setLyric', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'PUT',
          body: JSON.stringify(send_json)
        }).then(response => response.json()
        ).then(data => {
          if (data['result'] === 'OK') {
            const saveButton = document.querySelector('#icon-save');
            saveButton.className = 'ph ph-check-circle';
            setTimeout(() => {
              saveButton.className = 'ph ph-floppy-disk-back';
            }, 1000);
          }
        }).catch(err => {
          console.log(err);
        });
      }}><i className="ph ph-floppy-disk-back" id='icon-save'></i></div>
      <div className="lyrics-button enlarge-lyrics" onClick={(e) => {
        setNumberAndDelay((arr) => {
          if (!arr[0]) return arr;
          return [arr[0] - 1, arr[1]]
        });
      }}><i className="ph ph-caret-double-left"></i></div>
      <div className="lyrics-button enlarge-lyrics" onClick={(e) => {
        setNumberAndDelay((arr) => {
          if (arr[0] + 1 === totalNumber) return arr;
          return [arr[0] + 1, arr[1]]
        });
      }}><i className="ph ph-caret-double-right"></i></div>
    </div>
  )
}

export default LyricsUI