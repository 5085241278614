import React from "react";
import "../../controller.css";

const ControlDetails = ({ isOpen, onClose, tracks, setCurrentTrackIndex }) => {
  if (!isOpen) return null; // 如果未打开，则不渲染
  function padding(num) {
    if (num < 10) return '0' + num;
    return num;
  }

  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        if (e.target.className === "modal-overlay") onClose();
      }}
    >
      <div className="modal-content">
        <h2>Playlist</h2>
        <ul>
          {tracks.map((song, index) => {
            return (
              <div
                key={index}
                role="row"
                aria-rowindex="2"
                aria-selected="true"
                onClick={() => {
                  setCurrentTrackIndex(index);
                  onClose();
                }}
              >
                <div className="track-row">
                  <div className="track-number" aria-colindex="1">
                    <span>{index + 1}</span>
                  </div>

                  <div className="track-info-box">
                    <div className="track-info" aria-colindex="2">
                      <span className="track-title">
                        {song.name}
                      </span>
                      <span className="track-artist">{song.artist}</span>
                    </div>
                  </div>

                  <div className="track-duration" aria-colindex="3">
                    <span>{parseInt(song.duration / 60) + ':' + padding(parseInt(song.duration) % 60)}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ControlDetails;
