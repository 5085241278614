import React, { useEffect, useState } from 'react'

const SearchBar = ({ albums, setFilteredAlbums }) => {
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setFilteredAlbums(
      albums.filter(
        (album) =>
          // track.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          album.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, albums, setFilteredAlbums]);

  return (
    <input
      id="input"
      type="search"
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  )
}

export default SearchBar