// 专辑组件
const Album = ({ album, onClick }) => {
  return (
    <div className="album-card">
      <div className="album-cover" onClick={onClick}>
        <img src={'albums/[Album' + album.albumid + ']/images/_folder_compressed.jpg'} alt={album.name} />
      </div>
      <div className="album-info">
        <span className="album-title">{album.name}</span>
        <div className="artist-name">
          <span>{album.artist}</span>
        </div>
      </div>
    </div>
  );
};

export default Album;
