// Playlist.js
import React, { useState } from "react";
import Modal from "./Modal";
import Album from "./Album";

function Playlist({ albums, setTracks, setCurrentTrackIndex, localTracks }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // 控制悬浮页面的状态
  const [albumIndex, setAlbumIndex] = useState(0);

  // 关闭悬浮页面
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="playlist">
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        album={albums[albumIndex]}
        setCurrentTrackIndex={setCurrentTrackIndex}
        setTracks={setTracks}
        localTracks={localTracks}
      />
      <div className="album-container">
        <ul>
          {albums.map((album, index) => (
            <Album
              key={index}
              setCurrentTrackIndex={setCurrentTrackIndex}
              album={album}
              onClick={() => {
                setAlbumIndex(index);
                setIsModalOpen(true);
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Playlist;
