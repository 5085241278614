import React, { useEffect } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

const PlyrAudio = ({ setPlayer }) => {
  useEffect(() => {
    const player = new Plyr("#audio1", {
      controls: ["progress", "current-time", "duration", "mute", "volume"],
    });
    player.on("play", (event) => {
      document.querySelector("#play-pause-button i").className = "ph ph-pause";
    });
    player.on("pause", (event) => {
      document.querySelector("#play-pause-button i").className = "ph ph-play";
    });
    player.on("ended", (event) => {
      // console.log(player);
      document.querySelector("#play-next").click();
    });

    setPlayer(player);
  }, [setPlayer]);
  return <audio id="audio1" preload="true" controls autoPlay />;
};

export default PlyrAudio;
