import { useEffect, useState } from "react";

// 悬浮页面组件
const Modal = ({ isOpen, setCurrentTrackIndex, onClose, album, setTracks, localTracks }) => {
  const [albumInfo, setAlbumInfo] = useState(null);
  useEffect(() => {
    if (!album) return;
    fetch('http://192.168.0.101:3500/albumInfos?albumid=' + album.albumid)
      .then((response) => response.json())
      .then((albumInfo) => setAlbumInfo(albumInfo));
  }, [album]);

  function padding(num) {
    if (num < 10) return '0' + num;
    return num;
  }

  if (!isOpen) return null; // 如果未打开，则不渲染
  if (!albumInfo) return null;
  // console.log(albumInfo);
  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        if (e.target.className === "modal-overlay") onClose();
      }}
    >
      <div className="modal-content"><div className="album-header">
        <img src={'albums/[Album' + album.albumid + ']/images/_folder_compressed.jpg'} alt="Album Cover" />
        <p>
          Album: <strong>{album.name}</strong> <br />
          By <strong>{album.artist}</strong> <br />
          Release Date: <strong>{albumInfo.album.year + '-' + albumInfo.album.month}</strong><br />
          Rating: <strong> {albumInfo.album.score} (based on {albumInfo.album.count} votes)</strong><br />
          Genres: <strong>
            {albumInfo.genres.map((genre, index) => <span key={index} className="genre-tag">{genre.name}</span>)}
          </strong>
        </p>
      </div>
        <ul>
          {localTracks[album.albumid].map((song, index) => {
            return (
              <div
                key={index}
                role="row"
                aria-rowindex="2"
                aria-selected="true"
                onClick={() => {
                  setCurrentTrackIndex(index);
                  setTracks(localTracks[album.albumid].map((track, sindex) => {
                    return {
                      ...track,
                      'albumName': album.name
                    }
                  }));
                  onClose();
                }}
              >
                <div className="track-row">
                  <div className="track-number" aria-colindex="1">
                    <span>{song.number}</span>
                  </div>

                  <div className="track-info-box">
                    <div className="track-info" aria-colindex="2">
                      <span className="track-title">
                        {song.name}
                      </span>
                      <span className="track-artist">{album.artist}</span>
                    </div>
                  </div>

                  <div className="track-duration" aria-colindex="3">
                    <span>{parseInt(song.duration / 60) + ':' + padding(parseInt(song.duration) % 60)}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
        <div className="reviews-list">
          {albumInfo.reviews.map((review, index) =>
            <div className="review" key={index}>
              <p className="review-author">{review.author} ({review.score || '0'}/5) - {review.year + '.' + review.month}</p>
              <p>{review.content}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
