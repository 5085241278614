import React, { useState } from "react";
import ControlDetails from "./ControlDetails";

function PlayerControls({
  player,
  tracks,
  currentTrackIndex,
  setCurrentTrackIndex,
  setPageFlip,
  pageFlip,
  children,
}) {
  const [isControllerOpen, setIsControllerOpen] = useState(false);
  if (!tracks.length) return null;
  const track = tracks[currentTrackIndex];
  const cover_url = 'albums/[Album' + track.albumid + ']/images/_folder_compressed.jpg';
  return (
    <div className="player-controls">
      <div className="container-detail-header">
        <div className="controls-mask" style={{ 'backgroundImage': 'url(' + cover_url + ')' }}></div>
        <div className="headings">
          <h1 className="headings__title">
            <span>{track.name}</span>
          </h1>
          <div className="headings__subtitles">{track.albumName}</div>
          {/* <div className="headings__metadata-bottom">2014 年</div> */}
        </div>
        <div className="artwork">
          <img src={cover_url} alt="" />
        </div>
        <div className="cotroller">
          {children}
          <ControlDetails
            setCurrentTrackIndex={setCurrentTrackIndex}
            isOpen={isControllerOpen}
            onClose={setIsControllerOpen}
            tracks={tracks}
          />
          <div id="player-controls">
            <div className="control">
              <div
                className="button"
                id="play-previous"
                onClick={() => {
                  setCurrentTrackIndex((currentTrackIndex) => {
                    return currentTrackIndex
                      ? currentTrackIndex - 1
                      : tracks.length - 1;
                  });
                }}
              >
                <i className="ph ph-skip-back"></i>
              </div>
            </div>
            <div
              className="control"
              onClick={(e) => {
                if (player.paused) {
                  player.play();
                } else {
                  player.pause();
                }
              }}
            >
              <div className="button" id="play-pause-button">
                <i className="ph ph-play"></i>
              </div>
            </div>
            <div
              className="control"
              onClick={() => {
                setCurrentTrackIndex((currentTrackIndex) => {
                  return currentTrackIndex === tracks.length - 1
                    ? 0
                    : currentTrackIndex + 1;
                });
              }}
            >
              <div className="button" id="play-next">
                <i className="ph ph-skip-forward"></i>
              </div>
            </div>
            <div
              className="control"
              onClick={() => {
                setIsControllerOpen(true);
              }}
            >
              <div className="button" id="play-list">
                <i className="ph ph-playlist"></i>
              </div>
            </div>
            <div
              className="control"
              onClick={() => {
                setPageFlip((state) => {
                  return !state;
                })
              }}
            >
              <div className="button" id="zoom-in-out">
                <i className={pageFlip ? "ph ph-arrows-in-simple" : "ph ph-arrows-out-simple"}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerControls;
